/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { graphql, StaticQuery, Link } from 'gatsby';
import shortid from 'shortid';
import SVG from 'react-inlinesvg';
import { rgba } from 'polished';

import logo from '../../assets/images/logo/apex_logo_white.svg';
import constructionlineLogo from '../../assets/images/accrediation_logos/Constructionline-logo-white.png';
import chasLogo from '../../assets/images/accrediation_logos/CHAS-white.png';
import iso9001Logo from '../../assets/images/accrediation_logos/ISO-9001-2015.png';

// Breakpoints
import { bp } from '../../config/breakpoints';

// Components
import { themeVars } from '../../config/styles';
import SocialList from '../Common/Social/SocialList';

const cssFooter = css`
  background-color: ${themeVars.root.primary};
  padding: 50px 0;
  color: ${rgba('#FFF', 0.8)};

  @media (max-width: ${bp.mx_md}) {
    text-align: center;
  }

  @media (min-width: ${bp.mn_lg}) {
    text-align: left;
    padding: 100px 0;
  }

  a {
    color: ${rgba('#FFF', 0.8)};
    transition: 250ms ease-in-out all;

    &:hover {
      color: #fff;
    }
  }
`;

const cssSecondaryNav = css`
  margin-top: 40px;
`;

const cssFooterLeftWrapper = css`
  @media (min-width: ${bp.mn_lg}) {
    display: flex;
  }
`;

const cssFooterLeftContent = css`
  & > p {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: ${bp.mn_lg}) {
    flex-direction: column;

    & > p {
      margin-top: 0;
    }
  }
`;

const cssLogo = css`
  @media (min-width: ${bp.mn_lg}) {
    margin-right: 4rem;
    margin-left: 0;
  }
    width: 160px;
`;

const cssMenuList = css`
  list-style: none;
  margin-bottom: 4rem;
  padding: 0;

  @media (min-width: ${bp.mn_lg}) {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
  }

  li {
    margin-bottom: 15px;
  }
`;

const cssMenuInline = css`
  display: flex;
  list-style: none;
  padding: 0;

  li {
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const cssColThreeWrapper = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${bp.mn_lg}) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align: flex-start;
  }
`;

const cssAccreditationLogo = css`
  width: 150px;
  height: auto;
  margin: 1rem;
`;

const cssAccreditation = css`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: flex-start;
   
   @media (max-width: ${bp.mx_md}) {
    justify-content: center;
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }

        wordpressWpApiMenusMenusItems(name: { eq: "Footer" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={(data) => {
      const d = new Date();
      const year = d.getFullYear();

      return (
        <footer css={cssFooter}>
          <Container>
            <Row justify="between">
              <Col xl={6} md={6} sm={12} xs={12}>
                <div css={cssFooterLeftWrapper}>
                  <SVG css={cssLogo} alt={data.wordpressSiteMetadata.name} src={logo} />
                  <div css={cssFooterLeftContent}>
                    <p>
                      Copyright &copy; {data.wordpressSiteMetadata.name} {year}
                    </p>
                    <div css={cssAccreditation}>
                      <img css={cssAccreditationLogo} alt="Constructionline Accreditation Logo" src={constructionlineLogo} />
                      <img css={cssAccreditationLogo} alt="CHAS Accreditation Logo" src={chasLogo} />
                      <img css={cssAccreditationLogo} alt="ISO 9001 2015 Accreditation Logo" src={iso9001Logo} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={4} sm={12} xs={12}>
                <nav>
                  <ul css={cssMenuList}>
                    {data.wordpressWpApiMenusMenusItems.items.map((item) => (
                      <li key={shortid.generate()}>
                        <Link to={`/${item.object_slug}/`}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </Col>

              <Col xl={2} md={2} sm={12} xs={12} align={{ xl: 'right' }}>
                <div css={cssColThreeWrapper}>
                  <SocialList color={rgba('#FFF', 0.8)} hoverColor="#FFF" />

                  <nav css={cssSecondaryNav}>
                    <ul css={cssMenuInline}>
                      <li>
                        <Link to="/privacy/">Privacy &amp; Cookies</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      );
    }}
  />
);

export default Footer;
