/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const Section = (props) => {
  const cssWrapper = css`
    ${props.backgroundColor && `background-color: ${props.backgroundColor}!important;`}
    padding: ${props.paddingTop}rem 0 ${props.paddingBottom}rem!important;
  `;

  return <section css={cssWrapper}>{props.children}</section>;
};

Section.defaultProps = {
  backgroundColor: null,
  paddingBottom: 10,
  paddingTop: 10,
};

Section.propTypes = {
  /* The child nodes */
  children: PropTypes.node.isRequired,
  /* The background colour of the section  */
  backgroundColor: PropTypes.string,
  /** The margin in rem the bottom of the section. */
  paddingBottom: PropTypes.number,
  /** The margin in rem the bottom of the section. */
  paddingTop: PropTypes.number,
};

export default Section;
