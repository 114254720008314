/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import shortid from 'shortid';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';

// Components
import SocialIcon from './SocialItem';

// Vars
import { themeVars } from '../../../config/styles';

// Add custom icons
library.add(faFacebookF);
library.add(faTwitter);
library.add(faLinkedin);
library.add(faInstagram);

const cssSocialList = css`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 0 0 0;
`;

const SocialList = ({ color, hoverColor }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              options_social {
                profile_type
                profile_url
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ul css={cssSocialList}>
            {data.wordpressAcfOptions.options.options_social.map((profile) => {
              switch (profile.profile_type) {
                case 'facebook':
                  return (
                    <SocialIcon
                      color={color}
                      hoverColor={hoverColor}
                      icon={faFacebookF}
                      key={shortid.generate()}
                      url={profile.profile_url}
                    />
                  );
                case 'instagram':
                  return (
                    <SocialIcon
                      color={color}
                      hoverColor={hoverColor}
                      icon={faInstagram}
                      key={shortid.generate()}
                      url={profile.profile_url}
                    />
                  );
                case 'linkedin':
                  return (
                    <SocialIcon
                      color={color}
                      hoverColor={hoverColor}
                      icon={faLinkedin}
                      key={shortid.generate()}
                      url={profile.profile_url}
                    />
                  );
                case 'twitter':
                  return (
                    <SocialIcon
                      hoverColor={hoverColor}
                      color={color}
                      icon={faTwitter}
                      key={shortid.generate()}
                      url={profile.profile_url}
                    />
                  );
                default:
                  return null;
              }
            })}
          </ul>
        );
      }}
    />
  );
};

SocialList.defaultProps = {
  hoverColor: themeVars.root.primary,
};

SocialList.propTypes = {
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
};

export default SocialList;
