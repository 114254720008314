/**  */

// eslint-disable-next-line
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { rgba } from 'polished';

const SocialItem = ({ url, icon, color, hoverColor }) => {
  const cssIcon = css`
    margin-right: 15px;
    a {
      text-align: center;
      display: block;

      svg {
        path {
          transition: 250ms ease-in-out;
          fill: ${rgba(color, 0.8)};
        }

        &:hover {
          path {
            transition: 250ms ease-in-out;
            fill: ${hoverColor};
          }
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  `;

  return (
    <li css={cssIcon}>
      <a href={url} aria-label="Social profile" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon} color={color} size="lg" />
      </a>
    </li>
  );
};

SocialItem.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
};

export default SocialItem;
